import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import PungoInput from "../../../../pungo-ui/PungoInput";
import PungoButton from "../../../../pungo-ui/PungoButton";
import { isEmail, isPhone } from "../../../utils/validations";
import { Snackbar, Alert } from "@mui/material";

import styles from "./index.module.scss";

const ContactForm: React.FC = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("error");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleClose = () => {
    setOpenSnackbar(false);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = {
      email: email,
      phone: phoneNumber,
      name: name,
      lastName: lastName,
      company: company,
      message: message,
      app: window.location.href,
    };

    let msg = "";

    fetch("https://bsp6bzmy55q4azstlah2kmrxnq0mteae.lambda-url.us-east-1.on.aws", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          "Accept-Language": i18next.language,
        },
        body: JSON.stringify(formData),
    }).then((responseJson)=>{
      msg = t("fetchResponse.sendOk");
      setSnackBarSeverity("success");
      setSnackBarMsg(msg);
      setOpenSnackbar(true);
      setIsLoading(false)
      setEmail("");
      setPhoneNumber("");
      setName("");
      setLastName("");
      setCompany("");
      setMessage("");
    }).catch((error)=>{
      msg = t("fetchResponse.internetError");
      setSnackBarSeverity("error");
      setSnackBarMsg(msg);
      setOpenSnackbar(true);
      setIsLoading(false)
    })
      
    
  };

  const verifyEmail = (e: any) => {
    setEmail(e);
    if (isEmail(e)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  };

  const verifyPhoneNumber = (e: any) => {
    setPhoneNumber(e);
    if (isPhone(e)) {
      setIsValidPhone(true);
    } else {
      setIsValidPhone(false);
    }
  };

  let sendLabel = "";
  let disableSend = !isValidEmail || !isValidPhone || !name || !lastName || !company || email.length < 6 || isLoading;

  if (disableSend) {
    if (!isLoading) {
      sendLabel = t("contactForm.fillRequiredFields");
    } else {
      sendLabel = t("contactForm.sendingData");
    }
  } else {
    sendLabel = t("accountPage.signUp");
  }

  return (
    <>
      <PungoSectionCard title={t("ContactSection.title")} description={`${t("ContactSection.description")}`} id="contactUs" distribution="block">
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <PungoInput name={t("ContactSection.name")} value={name} onChange={(e: any) => setName(e)} required="required" />
            <PungoInput name={t("ContactSection.lastName")} value={lastName} onChange={(e: any) => setLastName(e)} required="required" />
            <PungoInput name={t("ContactSection.company")} value={company} onChange={(e: any) => setCompany(e)} required="required" />
            <PungoInput
              name={t("ContactSection.email")}
              value={email}
              onChange={(e: any) => verifyEmail(e)}
              helperText={isValidEmail ? undefined : `${t("accountPage.invalidEmail")}`}
              required="required"
            />
            <PungoInput name={t("ContactSection.phone")} value={phoneNumber} onChange={(e: any) => verifyPhoneNumber(e)} helperText={isValidPhone ? undefined : `${t("accountPage.invalidPhone")}`} />
            <PungoInput name={t("ContactSection.message")} value={message} onChange={(e: any) => setMessage(e)} multiline={true} rows={3} />
          </div>
          <div className={styles.submitSection}>
            <div className={styles.submitButton}>
              <PungoButton label={sendLabel} onClick={handleSubmit} color="tertiary" disabled={disableSend} classNames={styles.submitButtonOver} />
            </div>
          </div>

          <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}>
            <Alert severity={snackBarSeverity === "error" ? "warning" : "success"}>{snackBarMsg}</Alert>
          </Snackbar>

        </div>
      </PungoSectionCard>
    </>
  );
};

export default ContactForm;
