import React from "react";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import PungoButton from "../../pungo-ui/PungoButton";
import Alas from "../../assets/PungoIcons/Alas.svg";
import styles from "./index.module.scss";

interface ErrorPageProps {
  type: "error" | "ai" | "analytics" | "sustainability";
}

const ErrorPage: React.FC<ErrorPageProps> = (props) => {
  const { type } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getErrorTitle = (error:string)=>{
    switch (error) {
      case 'error':
        return t("errorPage.title.error");
      case 'ai':
        return t("errorPage.title.ai");
      case 'analytics':
        return t("errorPage.title.analytics");
      case 'sustainability':
        return t("errorPage.title.sustainability");
      default:
        return t("errorPage.title.error");
    }
  }

  const getErrorBody = (error:string)=>{
    switch (error) {
      case 'error':
        return t("errorPage.body.error");
      case 'ai':
        return t("errorPage.body.ai");
      case 'analytics':
        return t("errorPage.body.analytics");
      case 'sustainability':
        return t("errorPage.body.sustainability");
      default:
        return t("errorPage.body.error");
    }
  }

  const handleClick = (error:string) => {
    navigate("/");
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>{getErrorTitle(type)}</div>
        <div className={styles.body}>
          <Trans>
            {getErrorBody(type)}
          </Trans>
        </div>
        <div className={styles.button}>
          <PungoButton
            label={`${t("errorPage.button")}`}
            onClick={()=>handleClick(type)}
            color="tertiary"
          />
        </div>
      </div>
      <img className={styles.gif} src={Alas} alt="Text animation" />
    </div>
  );
};

export default ErrorPage;
