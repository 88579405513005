import React from "react";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import instance from "./translations/i18n";
import PungoPage from "./components/PungoPage";
import ErrorPage from "./components/ErrorPage";

import "./App.css";

const App = () => (
  <div className="App">
    <Router>
      <I18nextProvider i18n={instance}>
        <Routes>
          <Route path="/" element={<PungoPage />} />
          <Route path="*" element={<ErrorPage type="error" />} />
          <Route path="/analytics" element={<ErrorPage type="analytics" />} />
          <Route path="/ai" element={<ErrorPage type="ai" />} />
          <Route path="/sustainability" element={<ErrorPage type="sustainability" />} />
        </Routes>
      </I18nextProvider>
    </Router>
  </div>
);

export default App;
